import React, { Component } from "react";
import { Button, Select, InputNumber } from "antd";
import { exportPDF } from "../common/exportPDF";
import "../css/mathview.css";
import MathExerciseUtils from "../common/mathExerciseUtils";
// import tzg from "../assets/images/tzg.svg";
// import mzg from "../assets/images/mzg.svg";
// import mzhgg from "../assets/images/mzhgg.svg";
// import sxg from "../assets/images/sxg.svg";

const optionsclass = [
  { value: "1", label: "加法" },
  { value: "2", label: "减法" },
  { value: "11", label: "加减混合" },
];

const optionsrange = [
  { value: "5", label: "0-5" },
  { value: "10", label: "0-10" },
  { value: "20", label: "0-20" },
  { value: "50", label: "0-50" },
  { value: "100", label: "0-100" },
  { value: "200", label: "0-200" },
  { value: "1000", label: "0-1000" },
  { value: "10000", label: "0-10000" },
];

const optionsmovebit = [
  { value: "0", label: "不借位" },
  { value: "1", label: "破十法" },
  { value: "2", label: "2位" },
  { value: "3", label: "3位" },
];

var optionsData = {
  class: "",
  range: "",
  movebit: "",
  count: 10,
};

const handleClassChange = (value) => {
  optionsData.class = value;
};

const handleRangeChange = (value) => {
  optionsData.range = value;
};

const handleMovebitChange = (value) => {
  optionsData.movebit = value;
};

const handleCountChange = (value) => {
  optionsData.count = value;
};

const isOption = () => {
  if (
    optionsData.class !== "" &&
    optionsData.range !== "" &&
    optionsData.movebit !== "" &&
    optionsData.count > 0
  ) {
    return true;
  } else {
    return false;
  }
};

class MathExercises extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    this.state = {
      list: [], // Your list data
      nowdate: date,
      title: date,
    };
    this.pdfRef = React.createRef();
  }

  // 点击导出PDF
  onExportPDF = () => {
    console.log("log");
    const date = new Date(); // 乘以1000，因为JavaScript中的时间戳是毫秒级的
    // 获取日期和时间
    const formattedDate = date.toISOString(); // 这将返回一个ISO格式的字符串，例如"2021-11-13T12:00:00.000Z"
    exportPDF(formattedDate, this.pdfRef.current);
  };

  onMakeExercise = () => {
    console.log("onMakeExercise");
    if (!isOption()) {
      alert("请先选择题目类型！");
      return;
    }
    this.setState({
      list: MathExerciseUtils.makeExercise(
        optionsData.count,
        optionsData.class,
        optionsData.range,
        optionsData.movebit
      ),
    });
  };

  render() {
    return (
      <div className="main-container" style={{ background: "#fff" }}>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            width: "100%",
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div className="options-class">
            <h> 计算分类： </h>
            <Select
              defaultValue="请选择"
              style={{ width: 120 }}
              onChange={handleClassChange}
              options={optionsclass}
            />
          </div>
          <div className="options-range">
            <h> 数字范围： </h>
            <Select
              defaultValue="请选择"
              style={{ width: 120 }}
              onChange={handleRangeChange}
              options={optionsrange}
            />
          </div>
          <div className="options-movebit">
            <h> 借位： </h>
            <Select
              defaultValue="请选择"
              style={{ width: 120 }}
              onChange={handleMovebitChange}
              options={optionsmovebit}
            />
          </div>
          <div className="options-count" style={{ display: "flex" }}>
            <h> 练习数量： </h>
            <InputNumber
              min={1}
              max={10000}
              defaultValue={10}
              onChange={handleCountChange}
            />
          </div>
          <Button
            className="makeBut"
            type="primary"
            onClick={this.onMakeExercise}
          >
            生成题目
          </Button>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={this.onExportPDF}>
            导出PDF
          </Button>
        </div>
        <div
          ref={this.pdfRef}
          style={{
            padding: 30,
            boxSizing: "border-box",
            maxWidth: "1336px",
            // margin: "0 auto",
            lineHeight: "30px",
            fontSize: 14,
          }}
        >
          <h1>{this.state.nowdate}</h1>
          <ul>
            {this.state.list.map((item, index) => (
              <li
                key={index}
                className={index > 50 && 54 > index ? "lineli" : null}
              >
                <label>{item.mathExercise}</label>
                <div className="result" />
                <div className="result" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default MathExercises;
