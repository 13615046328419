let MathExerciseUtils = {
  makeExercise: function (count, mathClass, mathRange, movebit) {
    if (!count || !mathClass || !mathRange || !movebit) {
      return "Error: 参数错误";
    }
    let exercises = [];
    let selectedExercises = [];
    let mathExerciseItem = {};
    for (let i = 0; i < count; i++) {
      mathExerciseItem.mathClass = mathClass;
      mathExerciseItem.mathRange = mathRange;
      mathExerciseItem.movebit = movebit;
      let numbers = Array.from({ length: mathRange }, (_, i) => i + 1);
      // 生成题目
      for (let i = 0; i < numbers.length; i++) {
        for (let j = 0; j < numbers.length; j++) {
          if (movebit === "01" && j > 8) continue; // 如果 movebit 是 '01'，则第二个数为个位数
          let symbol = "+";
          mathExerciseItem = {};
          mathExerciseItem.one = numbers[i];
          mathExerciseItem.two = numbers[j];
          mathExerciseItem.move = movebit;
          mathExerciseItem.symbolstr = symbol;
          mathExerciseItem.result = mathExerciseItem.one + mathExerciseItem.two;
          mathExerciseItem.mathExercise =
            mathExerciseItem.one + symbol + mathExerciseItem.two + "=";

          if (mathClass !== "2") {
            if (movebit === "0" && checkDigitsChange(mathExerciseItem) === 0) {
              exercises.push({ ...mathExerciseItem });
            } else if (
              movebit === "1" &&
              checkDigitsChange(mathExerciseItem) === 1
            ) {
              exercises.push({ ...mathExerciseItem });
            } else if (
              movebit === "2" &&
              checkDigitsChange(mathExerciseItem) === 2
            ) {
              exercises.push({ ...mathExerciseItem });
            } else if (
              movebit === "3" &&
              checkDigitsChange(mathExerciseItem) === 3
            ) {
              exercises.push({ ...mathExerciseItem });
            }
          }

          if (numbers[i] > numbers[j] && mathClass !== "1") {
            symbol = "-";
            mathExerciseItem.symbolstr = symbol;
            mathExerciseItem.result = numbers[i] - numbers[j];
            mathExerciseItem.mathExercise =
              mathExerciseItem.one + symbol + mathExerciseItem.two + "=";
            if (movebit === "0" && checkDigitsChange(mathExerciseItem) === 0) {
              exercises.push({ ...mathExerciseItem });
            } else if (
              movebit === "1" &&
              checkDigitsChange(mathExerciseItem) === 1 &&
              mathExerciseItem.two < 10 &&
              mathExerciseItem.one < 20
            ) {
              exercises.push({ ...mathExerciseItem });
            } else if (
              movebit === "2" &&
              checkDigitsChange(mathExerciseItem) === 2
            ) {
              exercises.push({ ...mathExerciseItem });
            } else if (
              movebit === "3" &&
              checkDigitsChange(mathExerciseItem) === 3
            ) {
              exercises.push({ ...mathExerciseItem });
            }
          }
        }
      }
    }
    // 打乱题目顺序
    exercises = removeDuplicates(exercises);

    exercises.sort(() => Math.random() - 0.5);
    selectedExercises = exercises.slice(0, count);

    let counter = 0;
    if (exercises.length < count) {
      exercises.sort(() => Math.random() - 0.5);
    }
    while (selectedExercises.length < count) {
      let index = counter % exercises.length; // 计算索引，如果数组长度超过原始长度，就从头开始
      console.log("index: ", index);
      console.log("exercises: ", exercises.length);

      selectedExercises.push(exercises[index]); // 将对应的元素添加到数组的末尾
      counter++;
    }

    return selectedExercises;
  },
};

function checkDigitsChange(mathExerciseItem) {
  let resultOtherDigits = Math.floor(mathExerciseItem.result / 10);
  let oneOtherDigits = Math.floor(mathExerciseItem.one / 10);

  let resultTens = Math.floor(mathExerciseItem.result / 100);
  let oneTens = Math.floor(mathExerciseItem.one / 100);

  let resultHundreds = Math.floor(mathExerciseItem.result / 1000);
  let oneHundreds = Math.floor(mathExerciseItem.one / 1000);
  if (resultOtherDigits === oneOtherDigits) {
    // 除了个位数以外的其他位数没有变化
    console.log("resultOtherDigits: ", 0);

    return 0;
  } else if (resultTens === oneTens) {
    // 变化了十位数和个位数
    return 1;
  } else if (resultHundreds === oneHundreds) {
    // 变化了百位数
    return 2;
  } else {
    // 变化了更多的位数
    return 3;
  }
}

function removeDuplicates(array) {
  let unique = array.filter(
    (v, i, a) =>
      a.findIndex(
        (t) => t.one === v.one && t.two === v.two && t.symbolstr === v.symbolstr
      ) === i
  );
  return unique;
}
export default MathExerciseUtils;
